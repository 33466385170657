import React from 'react';
import { Widget } from '../widget/widget';
import { MostActiveStat } from '../dashboard/dashboard.types';
import { config } from '../../security/info';

interface Props {
    views: MostActiveStat[];
}

export const MostViewed = ({ views }: Props) => {
    return (
        <Widget width={2} height={200} title="Most Viewed">
            {views.map((c) => {
                return (
                    <div key={`viewed-${c.username}`}>
                        <a href={`${config.fizlinkurl}/${c.username}`} target="_blank" rel="noreferrer" style={{ marginRight: '8px' }}>
                            @{c.username}
                        </a>
                        ({c.count})
                    </div>
                );
            })}
        </Widget>
    );
};
