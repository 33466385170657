import React, { useState } from 'react';
import { Wrapper } from '../wrapper/wrapper';
import { SearchResultsProps } from './search.types';
import { useMobileDevice } from '../../hooks/device.hooks';
import { UserItem } from '../user-item/user-item';
import { config } from '../../security/info';
import { HorizontalLoader } from '../loader/horizontal-loader';

export const SearchResults = ({ users, loading, nextPage, onHide }: SearchResultsProps) => {
    const isMobileDevice = useMobileDevice();
    const [className] = useState(isMobileDevice ? 'search-results mobile' : 'search-results');

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
        const target = e.target as any;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        if (bottom) {
            nextPage();
        }
    };

    return (
        <Wrapper onClick={onHide}>
            <div className={className} onScroll={handleScroll}>
                {users.map((u) => <UserItem id={u._id} icon={`${config.serverurl}/api/medias/${u.media}`} fullname={u.fullname} username={u.username} verified={u.verified} enterprise={u.enterprise} />)}
                {loading && <HorizontalLoader />}
            </div>
        </Wrapper>
    );
};
