import { useCallback, useEffect, useState } from 'react';
import { SearchProps, UserSearch } from './search.types';
import { config } from '../../security/info';

export const useSearch = (props: SearchProps) => {
    const { token } = props;
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState<UserSearch[]>([]);
    const [loading, setLoading] = useState(false);

    const onQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.currentTarget.value);
    }, []);

    const onHideResults = useCallback(() => {
        setQuery("");
    }, []);

    const handleNextPage = useCallback(() => {
        setPage(page + 1);
    }, [page]);

    const performSearch = useCallback((page = 1, previousUsers: UserSearch[] = []) => {
        setLoading(true);
        if (query) {
            const limit = 20;
            const encodedQuery = encodeURI(query);
            fetch(`${config.serverurl}/api/search/all?query=${encodedQuery}&page=${page}&limit=${limit}`, {
                method: 'get',
                headers: { "Content-type": "application/json; charset=UTF-8", "x-access-token": token }
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (data) {
                        setUsers([...previousUsers ?? [], ...data]);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        }
        else {
            setLoading(false);
            setUsers([]);
        }
    }, [token, query]);

    useEffect(() => {
        performSearch(1);
    }, [performSearch, query]);

    useEffect(() => {
        performSearch(page, users);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performSearch, page]);

    return { query, users, loading, onQueryChange, onHideResults, handleNextPage };
};