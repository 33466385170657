import React from 'react';
import { FizLink } from '../fizlink-logo';
import { useLogin } from './login.hooks';
import { LoginProps } from './login.types';

import './login.css';

export const Login = (props: LoginProps) => {
    const { username, password, error, onUsernameChange, onPasswordChange, login } = useLogin(props);

    return (
        <div className="login">
            <div className="login-form card">
                <div className="login-header">
                    <FizLink height={48} />
                    <span className="admin-text">Admin</span>
                </div>
                <div className="card-body">
                    <form onSubmit={login}>
                        <input value={username} type="username" placeholder="Username" onChange={onUsernameChange} />
                        <input value={password} type="password" placeholder="Password" onChange={onPasswordChange} />
                        <button className="login-button" disabled={!username || !password}>Login</button>
                        {error && <div className="error"><span>{error}</span></div>}
                    </form>
                </div>
            </div>
        </div>
    );
};
