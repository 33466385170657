import React from 'react';
import { User } from '../dashboard/dashboard.types';
import { UserItem } from '../user-item/user-item';
import { Widget } from '../widget/widget';

interface Props {
    users: User[];
}

export const RecentUsersWidget = ({ users }: Props) => {
    return (
        <Widget width={8} title="Recent Users">
            {(users ?? []).map((u) => <UserItem key={`recent-user-${u._id}`} id={u._id} fullname={u.contact.fullname} username={u.username} creationdate={u.creationdate} status={u.status.description} verified={u.verified} enterprise={u.enterprise} />)}
        </Widget>
    );
};
