import React from 'react';

interface LoyaltyProps {
    width?: number;
    fill?: string;
}

export const Loyalty = ({ width, fill }: LoyaltyProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 24} viewBox="0 0 24 24" fill={fill ?? "#d7d7d7"}>
            <path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z"/>
            <circle cx="6.5" cy="6.5" r="1.5"/>
            <path d="M8.9 12.55c0 .57.23 1.07.6 1.45l3.5 3.5 3.5-3.5c.37-.37.6-.89.6-1.45 0-1.13-.92-2.05-2.05-2.05-.57 0-1.08.23-1.45.6l-.6.6-.6-.59c-.37-.38-.89-.61-1.45-.61-1.13 0-2.05.92-2.05 2.05z"/>
        </svg>
    );
};
