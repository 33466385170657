import { useCallback, useState } from "react";
import { config } from "../../security/info";
import { LoginProps } from './login.types';

export const useLogin = (props: LoginProps) => {
    const { setToken } = props;
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string>("");

    const onUsernameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.currentTarget.value);
    }, []);

    const onPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
    }, []);

    const login = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError("");
        fetch(`${config.serverurl}/api/users/superadminlogin`, {
            method: 'post',
            body: JSON.stringify({ username: username, password: password }),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.token) {
                    const token = data.token;
                    localStorage.setItem('currentUser', token);
                    setToken(token);
                }
                else {
                    setError(data.error);
                }
            })
            .catch(() => {
                setError("Error logging in.");
            });
    }, [username, password, setToken]);

    return { username, password, error, onUsernameChange, onPasswordChange, login };

};