import { ToolbarProps } from './toolbar.types';
import { useMobileDevice } from '../../hooks/device.hooks';
import { useCallback, useEffect, useState } from 'react';

export const useToolbar = (props: ToolbarProps) => {
    const { token, setToken } = props;
    const isMobileDevice = useMobileDevice();
    const [toolbarClassName] = useState(!isMobileDevice ? "toolbar" : "toolbar-mobile");
    const [fullname, setFullname] = useState("");

    useEffect(() => {
        try {
            const jwt = JSON.parse(atob(token.split('.')[1]));
            if (jwt.fullname) {
                setFullname(jwt.fullname);
            }
        } catch (e) {
            console.error(e);
        }
    }, [token]);

    const logout = useCallback(() => {
        setToken("");
    }, [setToken]);

    return { token, fullname, logout, isMobileDevice, toolbarClassName };
};