import { useCallback, useEffect, useState } from 'react';
import { config } from '../../security/info';
import { DashboardProps, DashboardStats, User, MostActiveStat } from './dashboard.types';

export const useDashboard = (props: DashboardProps) => {
    const { token, setToken } = props;
    const [total, setTotal] = useState(0);
    const [recent, setRecent] = useState<User[]>([]);
    const [today, setToday] = useState(0);
    const [statuses, setStatuses] = useState({});
    const [verified, setVerified] = useState(0);
    const [clicks, setClicks] = useState<MostActiveStat[]>([]);
    const [views, setViews] = useState<MostActiveStat[]>([]);

    useEffect(() => {
        fetch(`${config.serverurl}/api/users/stats`, {
            method: 'get',
            headers: { "Content-type": "application/json; charset=UTF-8", "x-access-token": token }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && !data.error) {
                    const stats = data as DashboardStats;
                    setTotal(stats.total);
                    setRecent(stats.recent);
                    setToday(stats.today);
                    setStatuses(stats.statuses);
                    setVerified(stats.verified);
                    setClicks(stats.clicks);
                    setViews(stats.views);
                }
                else {
                    setToken("");
                }
            })
            .catch(() => {
                setToken("");
            });
    }, [token, setToken]);

    const verifyUser = useCallback((id: string) => {
        const users = recent.slice();
        const user = users.find((u) => u._id === id);
        if (user) {
            user.verified = true;
            setRecent(users);
        }
    }, [recent]);

    const markAsEnterprise = useCallback((id: string) => {
        const users = recent.slice();
        const user = users.find((u) => u._id === id);
        if (user) {
            user.enterprise = !user.enterprise;
            setRecent(users);
        }
    }, [recent]);

    return { token, total, recent, today, statuses, verified, clicks, views, verifyUser, markAsEnterprise };
};