import React from 'react';
import { Verified } from '../svg/verified';
// import { Pie, PieChart } from 'recharts';
import { Widget } from '../widget/widget';

interface StatusesProps {
    statuses: { [s: string]: number };
    verified: number;
}

export const StatusesWidget = ({ statuses, verified }: StatusesProps) => {
    return (
        <Widget width={2} height={200}>
            {Object.keys(statuses ?? {}).map((s) => <div key={`status-${s}`}><b>{s}</b>: {statuses[s]}</div>)}
            <div key={`verified`} style={{ display: 'flex', alignItems: 'center' }}><b>Verified</b>: {verified} {<Verified width={16} />}</div>
            {/* <PieChart height={150}> */}
                {/* <Pie data={Object.keys(statuses).map((k) => { return { name: k, value: statuses[k] } })} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" /> */}
                {/* <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label /> */}
            {/* </PieChart> */}
        </Widget>
    );
};
