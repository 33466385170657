import React, { useCallback } from 'react';
import { Widget } from '../widget/widget';

import './count-widget.css';

interface Props {
    title: string;
    count: number;
}

export const CountWidget = ({ title, count }: Props) => {

    const formatNumber = useCallback((value: number) => {
        if (isNaN(value)) {
            return "0";
        }
        let result = value.toFixed(0);
        if (value >= 1000000) {
            result = `${(value / 100000).toFixed(0)}M`
        }
        else if (value >= 10000) {
            result = `${(value / 1000).toFixed(0)}K`
        }
        return result;
    }, []);

    return (
        <Widget width={2} height={200} title={title}>
            <div className="count-widget">
                <h1>
                    {formatNumber(count)}
                </h1>
            </div>
        </Widget>
    );
};
