import React from 'react';
import { Toolbar } from '../toolbar/toolbar';
import { DashboardProps, DashboardContext } from './dashboard.types';
import { useDashboard } from './dashboard.hooks';
import { CountWidget } from '../widgets/count-widget';
import { RecentUsersWidget } from '../widgets/recent-users-widget';
import { StatusesWidget } from '../widgets/statuses-widget';
import { MostClicked } from '../widgets/most-clicked';
import { MostViewed } from '../widgets/most-viewed';

import './dashboard.css';

export const Dashboard = (props: DashboardProps) => {
    const { token, total, recent, today, statuses, verified, clicks, views, verifyUser, markAsEnterprise } = useDashboard(props);
    return (
        <DashboardContext.Provider value={{
            token, verifyUser, markAsEnterprise
        }}>
            <Toolbar {...props} />
            <div className="dashboard">
                <CountWidget title="Total Users" count={total} />
                <CountWidget title="Sign Ups Today" count={today} />
                <StatusesWidget statuses={statuses} verified={verified} />
                <MostClicked clicks={clicks} />
                <MostViewed views={views} />
                <RecentUsersWidget users={recent} />
            </div>
        </DashboardContext.Provider>
    );
};
