import { createContext } from "react";

export interface DashboardProps {
    token: string;
    setToken: (token: string) => void;
}

export interface User {
    _id: string;
    contact: {
        _id: string;
        email: string;
        firstname: string;
        lastname: string;
        fullname: string;
    };
    creationdate: string;
    language: {
        code: string;
        name: string;
        description: string;
    };
    status: {
        code: string;
        name: string;
        description: string;
    };
    username: string;
    verified?: boolean;
    enterprise?: boolean;
}

export interface MostActiveStat {
    username: string;
    count: number;
}

export interface DashboardStats {
    total: number;
    recent: User[];
    today: number;
    statuses: { [s: string]: number };
    verified: number;
    clicks: MostActiveStat[];
    views: MostActiveStat[];
}

export interface DashboardContextProps {
    token: string | null;
    verifyUser: (id: string) => void;
    markAsEnterprise: (id: string) => void;
  }
  
  const defaultProps: DashboardContextProps = {
    token: null,
    verifyUser: () => undefined,
    markAsEnterprise: () => undefined,
  };
  
  export const DashboardContext = createContext(defaultProps);