export enum MobileDeviceType {
    WindowsPhone,
    AndroidPhone,
    iOSPhone,
}

export const useMobileDevice = (): MobileDeviceType | undefined => {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return MobileDeviceType.WindowsPhone;
    }

    if (/android/i.test(userAgent)) {
        return MobileDeviceType.AndroidPhone;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if ((/iPad|iPhone|iPod/.test(userAgent) || /CriOS/.test(userAgent)) && !window.MSStream) {
        return MobileDeviceType.iOSPhone;
    }

    return undefined;
};