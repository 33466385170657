import React, { useCallback, useContext, useState } from 'react';
import { config } from '../../security/info';
import { UserItemProps } from './user-item.types';
import { Verified } from '../svg/verified';
import { DashboardContext } from '../dashboard/dashboard.types';
import { Loyalty } from '../svg/loyalty';

import './user-item.css';

export const UserItem = ({ id, icon, fullname, username, creationdate, status, verified, enterprise }: UserItemProps) => {
    const { token, verifyUser, markAsEnterprise } = useContext(DashboardContext);
    const [sending, setSending] = useState(false);
    const [validationSent, setValidationSent] = useState(false);

    const markAsVerified = useCallback(() => {
        if (!verified) {
            fetch(`${config.serverurl}/api/users/verify/${id}`, {
                method: 'post',
                headers: { "Content-type": "application/json; charset=UTF-8", "x-access-token": token ?? "" }
            })
            .then(() => verifyUser(id));
        }
    }, [token, id, verified, verifyUser]);

    const setEnterprise = useCallback(() => {
        fetch(`${config.serverurl}/api/users/enterprise/${id}`, {
            method: 'post',
            headers: { "Content-type": "application/json; charset=UTF-8", "x-access-token": token ?? "" }
        })
        .then(() => markAsEnterprise(id));
    }, [token, id, markAsEnterprise]);

    const resendValidation = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        setSending(true);
        fetch(`${config.serverurl}/api/users/resend/${id}`, {
            method: 'post',
            headers: { "Content-type": "application/json; charset=UTF-8", "x-access-token": token ?? "" }
        })
        .then(() => {
            setSending(false);
            setValidationSent(true)
        });
    }, [token, id]);

    return (
        <div className="user-item-container">
            <a className="user-item" href={`${config.fizlinkurl}/${username}`} target="_blank" rel="noreferrer">
                <div className="user-profile">
                    {icon && <img className="user-icon" src={icon} alt={fullname} />}
                    <div className="user-display">
                        <span className="user-title">{fullname}</span>
                        <span className="user-subheader">@{username} {verified && <Verified width={16} />}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {status !== 'Active' && <button className="status" disabled={sending} style={{ cursor: 'pointer', margin: '0 8px', backgroundColor: sending ? 'lightgrey' : validationSent ? '#c7eb5c' : '#5cc7eb' }} onClick={validationSent ? undefined : resendValidation}>{validationSent ? 'Sent!' : 'Resend Validation'}</button>}
                    <div className="creation-date">
                        {creationdate && <span className="user-creation-date">{new Date(creationdate).toDateString()}</span>}
                        {status && <><br /><span className="status" style={{ backgroundColor: status === 'Active' ? '#c7eb5c' : '#5cc7eb' }}>{status}</span></>}
                    </div>
                </div>
            </a>
            <div className="verified-button" onClick={markAsVerified}>
                <Verified width={20} fill={verified ? undefined : "#d7d7d7"} />
            </div>
            <div className="verified-button" onClick={setEnterprise}>
                <Loyalty width={20} fill={enterprise ? "#00d478" : undefined} />
            </div>
        </div>
    );
};
