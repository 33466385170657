import React from 'react';
import ReactDOM from 'react-dom';
import FizLinkAdmin from './fizlink-admin';
import reportWebVitals from './reportWebVitals';
import { config } from './security/info';
import './index.css';

if (process?.env?.NODE_ENV === 'development') {
  config.serverurl = 'http://localhost:3333';
  config.clienturl = 'http://localhost:3001';
}

ReactDOM.render(<FizLinkAdmin />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
