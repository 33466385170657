import React from 'react';
import { WidgetProps } from './widget.types';
import { useMobileDevice } from '../../hooks/device.hooks';

import './widget.css';

export const Widget = ({ width, height, title, children }: WidgetProps) => {
    const isMobileDevice = useMobileDevice();
    return (
        <div className="card widget" style={{ width: isMobileDevice ? '100%' : `calc(100%/12*${width}`, height }}>
            {title && <h3>{title}</h3>}
            {children}
        </div>
    );
};
