import React from 'react';
import { FizlinkIcon } from '../fizlink-icon';
import { ToolbarProps } from './toolbar.types';
import { useToolbar } from './toolbar.hooks';
import { Search } from '../search/search';

import './toolbar.css';

export const Toolbar = (props: ToolbarProps) => {
    const { token, fullname, logout, isMobileDevice, toolbarClassName } = useToolbar(props);

    return (
        <header className={toolbarClassName}>
            <div className="toolbar-menu">
                <FizlinkIcon height={!isMobileDevice ? 32 : 24} />
                <Search token={token} />
            </div>
            <div className="toolbar-actions">
                <div><span>{fullname}</span></div>
                <button className="icon-button" onClick={logout}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path fill="#101010" d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" /></svg>
                </button>
            </div>
        </header>
    );
}
