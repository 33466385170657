import React, { useState } from 'react';
import { Login } from './components/login/login';
import { Dashboard } from './components/dashboard/dashboard';

import './fizlink-admin.css';

const FizLinkAdmin = () => {
  const [token, setToken] = useState(localStorage.getItem('currentUser'));

  if (token) {
    return <Dashboard token={token} setToken={setToken} />;
  }
  else {
    return <Login setToken={setToken} />;
  }
}

export default FizLinkAdmin;
