import React from 'react';
import { SearchProps } from './search.types';
import { useSearch } from './search.hooks';
import { SearchResults } from './search-results';

import './search.css';

export const Search = (props: SearchProps) => {
    const { query, users, loading, onQueryChange, onHideResults, handleNextPage } = useSearch(props);
    return (
        <React.Fragment>
            <input className="search" value={query} placeholder="Search FizLink" onChange={onQueryChange} />
            {query && <SearchResults users={users} loading={loading} onHide={onHideResults} nextPage={handleNextPage} />}
        </React.Fragment>
    );
};
